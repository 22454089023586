import { Injectable } from '@angular/core';
import { HelpService } from './help.service';

@Injectable({
  providedIn: 'root'
})
export class FiscalYearService {

  constructor(private helpService:HelpService) { }


  async getFiscalYears(){
   return await this.helpService.GetDataByHelpNo("hlpeventfiscalyears");
  }

  async getEventYears(){
   return await this.helpService.GetDataByHelpNo("hlpeventyears");
  }

}
