import { Settlement } from './settlement.model';
import { SettlementGroup } from './settlement-group.model';

export class SettlementStatus {
     ID:number = 0;
     StatusName :string = null;

     Settlement:Settlement[] = []; 
     SettleSettlement_Groupment:SettlementGroup[] = []; 
}
