import { Injectable } from '@angular/core';
import { HelpService } from './help.service';

@Injectable({
  providedIn: 'root'
})
export class SettlementGroupService {

  constructor(private helpService:HelpService) { }


  async getGlGroupsList(){
   return await this.helpService.GetDataByHelpNo("hlpglgrouplist");
  }

  async getGlGroupsDrp(){
   return await this.helpService.GetDataByHelpNo("hlpglgroupdrp");
  }
}
