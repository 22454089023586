import { Injectable } from '@angular/core';
import { HelpService } from './help.service';

@Injectable({
  providedIn: 'root'
})
export class SettlementListService {

  constructor(private helpService:HelpService) { }


  async getSettlementList(ClientID:any,CategoryID:any,StageID:any,EventID:any,Fiscalyear:any,VenueID:any,){
    var filters={
      clientid:ClientID,
      categoryid:CategoryID,
      stageid:StageID,
      eventid:EventID,
      fiscalyear:Fiscalyear,
      venueid:VenueID,
    }
   return await this.helpService.GetDataByHelpNo("hlpsettlementlistsp",filters);
  }
}
