import { SettlementGroup } from './settlement-group.model';
import { SettlementStatus } from './settlement-status.model';
import { SettlementStages } from './settlement-stages.model';
import { SettlementCategories } from './settlement-categories.model';
import { Settlement_SubEvent } from './settlement-sub-event.model';


export class Settlement {
    ID: number = 0;
    VenueID: number = null;
    FiscalYear: string = null;
    EventID: number = null;
    EntryNo: string = null;
    StageID: number = null;
    StatusID: number = null;
    CategoryID: string = null;
    CreatedBy: number = null;
    CreatedDate: Date = null;
    ModifiedBy: number = null;
    ModifiedDate: Date = null;
    Event: Event = null;
    ActualAttendance: number = null;
    /* SettlementGroup: SettlementGroup[] = [];   
     SettlementCategory: SettlementCategories[] = [];   
     SettlementStage: SettlementStages[] = [];   
     SettlementStatus: SettlementStatus[] = [];*/
    Settlement_SubEvent: Settlement_SubEvent[] = [];
}
