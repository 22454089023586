import { SettlementGroup } from './settlement-group.model';
import { SettlementStatus } from './settlement-status.model';
import { SettlementStages } from './settlement-stages.model';
import { SettlementCategories } from './settlement-categories.model';
import { Settlement_SubEvent } from './settlement-sub-event.model';
import { Event } from '../event/event-info/Event.model';
import { Venue } from '../../models/venue.model';

export class SettlementsList {
    ID: number = 0;
    VenueID: number = null;
    FiscalYear: string = null;
    EventID: number = null;
    EventEntryNo: number = null;
    StageID: number = null;
    StatusID: number = null;
    CategoryID: string = null;    
    CategoryName: string = null;    
    CreatedBy: number = null;
    CreatedDate: Date = null;
    ModifiedBy: string = null;
    ModifiedDate: Date = null;
    Dept_ModifiedBy: string = null;
    Dept_ModifiedDate: Date = null;
    EventName: string = null;
    Client: string = null;
    ActualAttendance: number = null;
    SettlementCategory: string = null;
    SettlementPerformancesDate: string = null;
    Event: Event = new Event();
    Settlement_SubEvent: Settlement_SubEvent[] = [];
    Settlement_Group: SettlementGroup[] = [];
    SettlementStage: SettlementStages = new SettlementStages;
    SettlementStatu: SettlementStatus = new SettlementStatus;
    Venue: Venue = new Venue;
    SettlementCategories: SettlementCategories = new SettlementCategories;
    WorkflowStatus:string = null;
    WorkflowUrl:string=null;
    VenueName:string=null;
    PLAmount:number= null;
    EntryNo:string = null;
}
